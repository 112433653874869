<template>
  <div class="materialUpload">
    <div v-if="list.isNeedFont" class="tipArea">
      <!-- list.auditStatus == 1 list.auditStatus == 3 -->
    </div>
    <div v-if="list.isNeedFont" :class="{'tipAreaBox': true, 'tipIng': true, 'tipFail': true}">
      <img v-if="list.auditStatus == 1" class="img" src="@/assets/img/shenhezhong.png" alt="" srcset="">
      <img v-if="list.auditStatus == 2 " class="img" src="@/assets/img/shenhesuccess.png" alt="" srcset="">
      <img v-if="list.auditStatus == 3" class="img" src="@/assets/img/shenheerr.png" alt="" srcset="">
      <div class="title">
        前置学历清查
      </div>

      <template v-if="list.auditStatus == 1">
        <div class="content">
          <div class="status">审核中</div>
          <span class="msg">您的资料已提交，审核中</span>
        </div>
      </template>
      <template v-if="list.auditStatus == 2">
        <div class="content">
          <div class="status">已通过</div>
          <span class="msg">您的资料已提交，审核已通过</span>
        </div>
      </template>
      <template v-if="list.auditStatus == 3">
        <div class="content">
            <div class="status">不通过</div>
            <span class="msg">资料审核不通过，请修改后再次提交</span>
        </div>
      </template>
    </div>
    <div v-if="list.auditStatus == 2 || list.auditStatus == 3" class="tfIdea">
      <span class="title">审核信息</span>
      <div class="content">
        <span>修改意见</span>
        <span class="auditContent">{{list.auditContent || '暂无'}}</span>
      </div>
    </div>
    <div class="uploadBox" v-if="list.isNeedFont">
      <span class="title">填写信息</span>
      <div class="material-item" v-for="(item, index) in list.values" :key="index">
        <div class="ci-title" :class="index ===  0 ? 'ci-title1' : ''">
          <span>
            <span style="color: #FF0202;" v-if="index===0">*</span>{{ item.name }}
          </span>
          <span @click="downEvent(item.sample)" v-if="item.sample">
            <img src="@/assets/img/warring.png" alt="">查看样例
          </span>
        </div>
        <div class="fc-area">
          <template v-if="item.filesUrl && item.filesUrl.length != 0">
            <div class="ke-show" v-for="(ite, ind) in item.filesUrl" :key="ind">
              <div class="img-ke items">
                <van-image fit="cover" :src="ite" />
                <span></span>
              </div>
              <div class="zhez">
                <img class="deleteI" src="@/assets/img/deleteIcon.png" alt="" v-show="list.auditStatus == 0 || list.auditStatus == 3" @click.stop="deleteEvent(index, ind)">
                <img class="previewI" src="@/assets/img/previewIcon.png" alt="" v-show="$way.isImageType(ite)" @click="previewEvent(ite)">
                <!-- <img class="downI" src="@/assets/img/downLoadIcon.png" alt="" @click="downEvent(ite)"> -->
              </div>
            </div>
          </template>
          <div v-if="isshowFile(item)" class="addImg" @click="handleUploadClick(item,index)">
            <!-- <img class="add" src="@/assets/img/stu1.png" alt="" srcset=""> -->
            <img class="add" src="@/assets/img/add.png" alt="" srcset="">
          </div>
        </div>
        <div class="ci-dec" v-if="item.prompt">
          {{ item.prompt }}
        </div>
      </div>
      <div class="btn-area" v-if="list.auditStatus == 0 || list.auditStatus == 3">
        <button class="enter_btn" @click="enterEvent">提交</button>
      </div>
    </div>
    <div class="uploadBox uploadBoxNone" v-else>无需学历清查</div>
    <!-- 上传选择 -->
    <van-action-sheet v-model="showUploadWay" :actions="actions" cancel-text="取消" close-on-click-action @select="onSelect" />

    <!--打开相机-->
    <input style="display: none" type="file" ref="videoDom" accept="image/*" capture="camera" @change="videoChange">

    <!-- 调用系统文件 -->
    <input style="display: none" type="file" ref="fileDom" @change="imgChange">
  </div>
</template>

<script>
import { ImagePreview, Toast } from 'vant'
export default {
  name: 'materialUpload',
  data () {
    return {
      exampleUpIdx: null,
      showUploadWay: false,
      actions: [
        { name: '拍照', value: 'video' },
        { name: '文件上传', value: 'image' }
      ],
      list: [],
      type: null
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      if (val && this.$route.name == 'materialUpload') {
        this.type = this.$store.state.deviceType
        this.getFrontDegreeEvent()
      }
    }
  },
  mounted () {
    if (sessionStorage.getItem('token')) {
      this.getFrontDegreeEvent()
    }
  },
  methods: {
    // 获取数据
    getFrontDegreeEvent () {
      this.$request.getFrontDegree().then(res => {
        if (res.data.code == 0) {
          const arr = res.data.data.values.map(item => {
            item.filesUrl = item.filesUrl ? item.filesUrl.split(',') : []
            return item
          })
          console.log(arr, 777)
          res.data.data.values = arr
          this.list = res.data.data
        }
      })
    },
    isshowFile (item) {
      const val = this.list.auditStatus == 1 || this.list.auditStatus == 2 ? false : !!((item.filesUrl && item.filesUrl.length < 2) || !item.filesUrl)
      return val
    },
    // 图片预览
    previewEvent (img) {
      ImagePreview([img])
    },
    downEvent (fUrl) {
      ImagePreview([fUrl])
      // if(this.type == 1)
      //   window.webkit.messageHandlers.gotoDownload.postMessage(fUrl)
      // else if(this.type == 2)
      //   window.Android.outWeb(fUrl)
    },
    deleteEvent (index, ind) {
      this.list.values[index].filesUrl.splice(ind, 1)
    },
    handleUploadClick (item, index) {
      // if (this.studentInfo.confirmStatus == 2) return;
      // this.example = item.id
      this.exampleUpIdx = index
      this.showUploadWay = !this.showUploadWay
    },
    // 上传方式选择
    onSelect (item) {
      if (item.value == 'video') {
        this.$refs.videoDom.click()
      } else if (item.value == 'image') {
        this.$refs.fileDom.click()
      }
    },
    // 图片上传
    async imgChange () {
      const inputDOM = this.$refs.fileDom
      const files = inputDOM.files[0]
      // let fileSize = files.size / 1024 / 1024
      // if(fileSize > 10) {
      //   this.Error('照片不大于10M')
      //   return
      // }
      const obj = await this.$uploadAli.uploadOss(files, 'any')
      if (obj.excelUrl) {
        this.list.values[this.exampleUpIdx].filesUrl.push(obj.excelUrl)
      }
      this.$refs.fileDom.value = ''
    },
    // 拍照上传
    async videoChange () {
      const inputDOM = this.$refs.videoDom
      const files = inputDOM.files[0]
      const fileSize = files.size / 1024 / 1024
      if (fileSize > 10) {
        this.Error('照片不大于10M')
        return
      }
      const obj = await this.$uploadAli.imgFileUploadOss(files, 'img')
      if (obj.excelUrl) {
        this.list.values[this.exampleUpIdx].filesUrl.push(obj.excelUrl)
      }
      this.$refs.videoDom.value = ''
    },
    enterEvent () {
      const arr = this._.cloneDeep(this.list.values)
      let flag = false
      let errName = null
      arr.forEach((item, index) => {
        console.log(item)
        if (item.filesUrl.length !== 2 && !flag && index === 0) {
          flag = true
          errName = item.name
        }
      })
      if (flag) {
        Toast.fail(`${errName}项未填`)
        return
      }
      // arr.forEach(item => {
      //   item.filesUrl = item.filesUrl.join(',')
      // })
      arr.forEach(item => {
        item.filesUrls = item.filesUrl
        delete item.filesUrl
      })
      this.$request.submitFrontDegree(arr).then(res => {
        if (res.data.code == 0) {
          Toast.success(res.data.msg || '操作成功')
          this.getFrontDegreeEvent()
          // this.goBack()
        }
      })
    },
    goBack () {
      if (this.type === 1) {
        window.webkit.messageHandlers.getData.postMessage('close()')
      } else if (this.type === 2) {
        window.Android.Close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .materialUpload {
    height: 100%;
    overflow: scroll;
    background-color: #EDEEF2;
    .tipArea {
      box-sizing: border-box;
      padding: 14px 24px;
      font-size: 13px;
      line-height: 20px;
      height: 339px;
      background: linear-gradient(180deg, #3870FF 34%, #EDEEF2 100%);
      position: absolute;
      width: 100%;
      z-index: 0;
      img {
        width: 16px;
        height: 16px;
        margin-right: 11px;
      }
    }
    .tipAreaBox {
      margin-top: 22px;
      position: relative;
      .img {
        position: absolute;
        right: 31px;
        bottom: 20px;
        width: 90px;
      }
      .title {
        text-align: center;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 19px;
        color: #FFFFFF;
      }
      .content  {
        padding: 33px 34px 11px;
        .status {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 21px;
          color: #FFFFFF;
          margin-bottom: 10px;
        }
        .msg {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #CDDBFD;
        }
      }
    }
    .tipIng {
    }
    .tipFail {

    }
    .tfIdea {
      margin: 0 13px 10px;
      position: relative;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 19px 20px;
      .title {
        font-family: PingFang-SC-Medium;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
      }
      .content {
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        font-size: 15px;
        color: #333333;
        padding: 25px 0 0;
        .auditContent {
          margin-left: 60px;
        }
      }
    }
    .uploadBox {
      position: relative;
      z-index: 1;
      margin: 22px 13px;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 19px 20px;
      background-color: #fff;
      .title {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
      }
      &.uploadBoxNone {
        padding: 30px;
        text-align: center;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
      }
      .material-item {
        background-color: #fff;
        box-sizing: border-box;
        padding: 16px 2px 24px;
        border-bottom: 1px solid #D2D2D2;
        &:last-child  {
          border-bottom: none;
        }
        .ci-title1 {
          margin-top: 8px;
        }
        .ci-title {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          & > span:nth-of-type(1) {
            width: 180px;
          }
          & > span:nth-of-type(2) {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #4473F9;
            display: flex;
            align-items: center;
            width: 80px;
            margin-left: 10px;
            img {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }
          }
        }
        .fc-area {
          display: flex;
          gap: 24px;
        }
        .ke-show {
          position: relative;
          width: 87px;
          height: 56px;
        }
        .addImg {
          width: 87px;
          height: 56px;
          border-radius: 6px;
          position: relative;
          background: #F4F6FA;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          & > img:nth-of-type(1) {
            width: 21px;
            height: 21px;
          }
          // & > img:nth-of-type(2) {
          //   width: 32px;
          //   height: 32px;
          //   position: absolute;
          //   left: 50%;
          //   top: 50%;
          //   transform: translate(-50%, -50%);
          // }
        }
        .img-ke {
          text-align: center;
        }
        .van-image {
          width: 87px;
          height: 56px;
          border-radius: 6px;
          overflow: hidden;
        }
        .zhez {
          position: absolute;
          width: 87px;
          height: 56px;
          border-radius: 6px;
          background-color: #33333388;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 17px;
            height: 17px;
          }
          .deleteI {
            position: inherit;
            right: -5px;
            top: -5px;
          }
          .downI {
            width: 30px;
            height: 30px;
          }
        }
        .notImg {
          width: 87px;
          height: 56px;
          text-align: center;
          background-color: #f9f9f9;
          word-break: break-all;
          display: flex;
          flex-direction: column;
          align-items: center;
          // justify-content: center;
          // margin: 0 auto;
          border-radius: 6px;
          font-size: 8px;
          box-sizing: border-box;
          padding: 17px 6px 10px;
          background-image: url("../../assets/img/fileIcon.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          img {
            width: 36px;
            height: 40px;
            margin-bottom: 6px;
          }
        }
        .ci-dec {
          margin-top: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #999999;
        }
      }
    }
    .btn-area {
      text-align: center;
      margin: 0 0 22px;
      .enter_btn {
        width: 286px;
        height: 42px;
        margin: 0 auto;
        background: #3869F5;
        border-radius: 21px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 17px;
        color: #FFFFFF;
        line-height: 16px;
        border: 0 none;
        &:active {
          color: #ffffffbe;
          background: #1a7ee2cb;
        }
      }
    }

  }
</style>
